import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export const useBillingEntriesExclude = () => {
  const { mutate, loading: BillingEntryLoading, onError } = useMutation(BILLING_ENTRY_EXCLUDE_MUTATION);

  onError((err) => {
    console.error('bookkeeper - useBillingEntriesExclude', err);
    this.$message.error(this.$t('commons.messages.action.error'));
  });

  return {
    excludeBillingEntry: (id) => mutate({ id, data: { exclude: true } }),
    BillingEntryLoading,
  };
};

const BILLING_ENTRY_EXCLUDE_MUTATION = gql`
  mutation excludeBillingEntry($id: ID!, $data: BookkeepingBillingEntryPatchInput) {
    billingEntryPatch(id: $id, data: $data) {
      id
    }
  }
`;
