import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useCreateBookkeepingAccount() {
  const { error } = useNotification();
  const { mutate, loading, onDone, onError } = useMutation(CREATE_BOOKKEEPING_ACCOUNT_MUTATION);

  onError((err) => {
    console.error('bookkeeping - useCreateBookkeepingAccount', err);
    error();
  });

  return {
    createBookkeepingAccount: mutate,
    loading,
    onDone,
    onError,
  };
}

const CREATE_BOOKKEEPING_ACCOUNT_MUTATION = gql`
  mutation bookkeepingAccountCreate($createParams: BookkeepingAccountCreateInput!) {
    bookkeepingAccountCreate(createParams: $createParams) {
      id
      type
      customerId
      supplierId
      bookkeeperId
      account
    }
  }
`;
