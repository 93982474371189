import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useBookkeepingAccounts(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(BOOKKEEPING_ACCOUNTS_QUERY, variables, options);
  const bookkeepingAccounts = computed(() => result?.value?.bookkeepingAccounts?.nodes ?? []);
  const loading = useLoading(queryLoading, variables, options);

  onError((err) => {
    console.error('useBookkeepingAccounts', err);
    error();
  });

  return {
    bookkeepingAccounts,
    loading,
    refetch,
  };
}

const BOOKKEEPING_ACCOUNTS_QUERY = gql`
  query bookkeepingAccounts($customerId: ID, $supplierId: ID, $bookkeeperId: ID, $type: BookkeepingAccountType) {
    bookkeepingAccounts(customerId: $customerId, supplierId: $supplierId, bookkeeperId: $bookkeeperId, type: $type) {
      nodes {
        id
        type
        customerId
        supplierId
        bookkeeperId
        account
      }
    }
  }
`;
