<template>
  <div @mousewheel="closeActions">
    <div class="mb-4 d-flex justify-content-between align-items-center">
      <h3>
        {{ $t('bookkeeping.bookkeepingManagement.unsentBillings.header') }}
      </h3>
      <span>
        <Button type="primary" @click="sendModalOpen = true">
          {{ $t('bookkeeping.sendModal.title.dataTransfer') }}
        </Button>
      </span>
    </div>
    <Table
      v-loading="loading"
      custom-class="unexported-billings-table"
      :columns="columns"
      :data="tableData"
      rounded
      border
      @row-click="handleRowClick"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageSize * currentPageIndex }}
      </template>
      <template #cell-actions="{ rowData, rowIndex }">
        <el-dropdown
          class="d-flex justify-content-center p-0"
          trigger="click"
          placement="bottom-start"
          @command="handleAction($event, rowData)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="icon"
            class="p-0 actions-btn text-typography-primary"
            :class="{ active: activeRowActionsIndex === rowIndex }"
            @click.stop="handleAction($event, rowIndex)"
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item :command="ACTIONS.EXCLUDE_ENTITY">
              <div class="d-flex align-items-center gap-2 text-danger">
                <TrashCanIcon />
                <p>{{ $t('bookkeeping.bookkeepingManagement.unsentDataTransferBillings.table.actions.exclude') }}</p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        v-if="billings.length && billings.length > pageSize"
        layout="prev, pager, next, jumper"
        small
        background
        :total="billings.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      />
    </div>
    <DataTransferSendingModal
      v-if="sendModalOpen"
      :billings="billings"
      :from-date="billingsFromDate"
      @close="sendModalOpen = false"
      @send="handleSendEvent"
    />

    <CompleteDataModal
      v-if="completeDataModalOpen"
      :total-entries-exported="totalEntriesExported"
      @download-file="downloadFile"
      @close="completeDataModalOpen = false"
    />
    <ExportOrValidateModal
      v-if="exportOrValidateModalOpen"
      :number-to-validate="billingIdsToUpdate.length"
      :total-number="billingIdsToUpdate.length + validIdsForExport.length"
      :loading="exporting"
      @validate="openBookkeepingWizardModal"
      @export="exportOnlyValid"
    />
    <ExcludeConfirmModal
      v-if="excludeConfirmTransactionId"
      @confirm="() => handleEntriesUpdate([excludeConfirmTransactionId])"
      @cancel="handleExcludeCancel"
    ></ExcludeConfirmModal>
    <ExportWizard
      v-if="bookkeepingWizardOpen"
      :invalid-billing-ids="billingIdsToUpdate"
      :valid-billing-ids-for-export="validIdsForExport"
      :all-billing-entries="billingEntries"
      :billings="billings"
      :customer="customer"
      :bookkeeper-id="currentTenantId"
      @export-entries="exportEntriesByBillingIds"
      @close="bookkeepingWizardOpen = false"
      @billing-entries-update="(entryIds) => handleEntriesUpdate(entryIds)"
    />
    <BillingEntriesScouting
      v-if="billingEntriesScoutingOpen"
      :all-billing-entries="billingEntries"
      :billings="billings"
      :initial-index="scoutingIndex"
      :customer="customer"
      :bookkeeper-id="currentTenantId"
      @close="billingEntriesScoutingOpen = false"
      @billing-entries-update="(entryIds) => handleEntriesUpdate(entryIds)"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { DateTime } from 'luxon';
import { includes, indexBy, prop, without } from 'ramda';

import { Table, Button } from '@/modules/core';
import { currency } from '@/locale/numberConfig';
import { useTenancy } from '@/modules/auth';
import { TrashCanIcon, KebabIcon } from '@/assets/icons';

import ExcludeConfirmModal from './ExcludeConfirmModal.vue';
import { useBillingsExportCreate, useBillingEntriesExclude } from '../compositions';
import { formatDateShort } from '../formatters';
import DataTransferSendingModal from './DataTransferSendingModal';
import CompleteDataModal from './CompleteDataModal';
import ExportOrValidateModal from './ExportOrValidateModal';
import ExportWizard from '../exportWizard/components/ExportWizard';
import BillingEntriesScouting from '../exportWizard/components/BillingEntriesScouting';

const TABLE_HEADERS = {
  SUPPLIER: 'supplier',
  TRANSACTION_TYPE: 'transactionType',
  REFERENCE: 'reference',
  DATE: 'date',
  DEBIT_ONE: 'debit1',
  DEBIT_TWO: 'debit2',
  CREDIT_ONE: 'credit1',
  DEBIT_AMOUNT_ONE: 'debitAmount1',
  DEBIT_AMOUNT_TWO: 'debitAmount2',
  CREDIT_AMOUNT: 'creditAmount',
  DETAILS: 'details',
  ACTIONS: 'actions',
};

const ACTIONS = {
  EXCLUDE_ENTITY: 'excludeEntity',
};

export default {
  name: 'UnexportedBillings',
  components: {
    Table,
    DataTransferSendingModal,
    CompleteDataModal,
    Button,
    ExportOrValidateModal,
    ExcludeConfirmModal,
    ExportWizard,
    BillingEntriesScouting,
    TrashCanIcon,
    KebabIcon,
  },
  props: {
    billings: { type: Array, required: true },
    billingEntries: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    customer: { type: Object, required: true },
  },
  setup() {
    const { billingsExportCreate } = useBillingsExportCreate();
    const { currentTenantId } = useTenancy();
    const { excludeBillingEntry } = useBillingEntriesExclude();

    return {
      excludeBillingEntry,
      TABLE_HEADERS,
      ACTIONS,
      sendModalOpen: ref(false),
      completeDataModalOpen: ref(false),
      exportOrValidateModalOpen: ref(false),
      billingsExportCreate,
      pageSize: 50,
      currentPageIndex: ref(0),
      downloadUrl: ref(''),
      billingIdsToUpdate: ref([]),
      validIdsForExport: ref([]),
      bookkeepingWizardOpen: ref(false),
      excludeConfirmTransactionId: ref(null),
      billingEntriesScoutingOpen: ref(false),
      scoutingIndex: ref(0),
      totalEntriesExported: ref(0),
      exporting: ref(false),
      currentTenantId,
      activeRowActionsIndex: ref(-1),
    };
  },
  computed: {
    columns() {
      return [
        { header: '#', key: 'index', width: '26px' },
        {
          header: this.translateTableHeader('supplier'),
          key: TABLE_HEADERS.SUPPLIER,
          width: '160px',
        },
        { header: this.translateTableHeader('transactionType'), key: TABLE_HEADERS.TRANSACTION_TYPE, width: '100px' },
        { header: this.translateTableHeader('reference'), key: TABLE_HEADERS.REFERENCE },
        {
          header: this.translateTableHeader('date'),
          key: TABLE_HEADERS.DATE,
          width: '100px',
        },
        { header: this.translateTableHeader('debit1'), key: TABLE_HEADERS.DEBIT_ONE, width: '100px' },
        { header: this.translateTableHeader('debit2'), key: TABLE_HEADERS.DEBIT_TWO, width: '100px' },
        { header: this.translateTableHeader('credit1'), key: TABLE_HEADERS.CREDIT_ONE, width: '100px' },
        { header: this.translateTableHeader('debitAmount1'), key: TABLE_HEADERS.DEBIT_AMOUNT_ONE },
        { header: this.translateTableHeader('debitAmount2'), key: TABLE_HEADERS.DEBIT_AMOUNT_TWO },
        { header: this.translateTableHeader('creditAmount'), key: TABLE_HEADERS.CREDIT_AMOUNT },
        { header: this.translateTableHeader('details'), key: TABLE_HEADERS.DETAILS },
        { header: '', key: TABLE_HEADERS.ACTIONS, width: '50px' },
      ];
    },
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },
    tableData() {
      const billingsByIds = indexBy(prop('id'), this.billings);
      return this.billingEntries
        .map((billingEntry) => {
          const bookkeepingEntry = billingEntry.bookkeepingEntry;
          const billing = billingsByIds[billingEntry.billingId];
          return {
            [TABLE_HEADERS.SUPPLIER]: billing?.supplier?.name,
            [TABLE_HEADERS.TRANSACTION_TYPE]: bookkeepingEntry?.code ?? '-',
            [TABLE_HEADERS.REFERENCE]: bookkeepingEntry?.reference ?? '-',
            [TABLE_HEADERS.DATE]: this.formatDateShort(bookkeepingEntry?.referenceDate),
            [TABLE_HEADERS.DEBIT_ONE]: this.getChargeAccount(bookkeepingEntry?.debits[0]),
            [TABLE_HEADERS.DEBIT_TWO]: this.getChargeAccount(bookkeepingEntry?.debits[1]),
            [TABLE_HEADERS.CREDIT_ONE]: this.getChargeAccount(bookkeepingEntry?.credits[0]),
            [TABLE_HEADERS.DEBIT_AMOUNT_ONE]: this.getChargeAmount(bookkeepingEntry?.debits[0]),
            [TABLE_HEADERS.DEBIT_AMOUNT_TWO]: this.getChargeAmount(bookkeepingEntry?.debits[1]),
            [TABLE_HEADERS.CREDIT_AMOUNT]: this.getChargeAmount(bookkeepingEntry?.credits[0]),
            [TABLE_HEADERS.DETAILS]: bookkeepingEntry?.details ?? '-',
            bookkeepingEntryId: billingEntry.id,
            bookkeepingEntry,
          };
        })
        .sort((a, b) => {
          if (!a.supplier || !b.supplier) {
            return 0;
          } else {
            return (
              a.supplier.localeCompare(b.supplier) * 2 +
              (a.bookkeepingEntry.referenceDate >= b.bookkeepingEntry.referenceDate ? 1 : -1)
            );
          }
        })
        .slice(this.currentPageIndex * this.pageSize, this.currentPageIndex * this.pageSize + this.pageSize);
    },
    billingsFromDate() {
      const billingsSortedByDate = [...this.billings].sort((a, b) => new Date(a.date) - new Date(b.date));
      return new Date(billingsSortedByDate[0].date).getTime();
    },
  },
  methods: {
    formatDateShort,
    async exportEntriesByBillingIds(billingIds) {
      try {
        const result = await this.billingsExportCreate({
          customerId: this.customer.id,
          bookkeeperId: this.currentTenantId,
          billingIds,
        });
        this.downloadUrl = result.data.billingsExportCreate.url;
        this.completeDataModalOpen = true;
        this.totalEntriesExported = billingIds.length;
        this.$emit('billings-export-sent'); // activates refetch
      } catch (err) {
        this.$message.error(this.$t('errors.action'));
      } finally {
        await this.$nextTick();
        this.bookkeepingWizardOpen = false;
      }
    },
    async handleSendEvent({ toDate }) {
      const requestedIdsForExport = this.billings
        .filter(({ date }) => date <= DateTime.fromJSDate(toDate).endOf('day').toISODate())
        .map(({ id }) => id);
      try {
        const result = await this.billingsExportCreate({
          customerId: this.customer.id,
          billingIds: requestedIdsForExport,
          bookkeeperId: this.currentTenantId,
        });
        this.downloadUrl = result.data.billingsExportCreate.url;
        this.completeDataModalOpen = true;
        this.totalEntriesExported = requestedIdsForExport.length;
        this.$emit('billings-export-sent'); // activates refetch
      } catch (err) {
        if (err.graphQLErrors?.length) {
          for (const gqlError of err.graphQLErrors) {
            if (gqlError.extensions?.response?.status === 409) {
              const idsToValidate = err.graphQLErrors[0]?.extensions?.response?.body?.invalidBillingIds;
              this.billingIdsToUpdate = idsToValidate;
              this.validIdsForExport = this.billingEntries
                .filter(
                  ({ billingId }) => !idsToValidate.includes(billingId) && requestedIdsForExport.includes(billingId)
                )
                .map(({ billingId }) => billingId);
              this.exportOrValidateModalOpen = true;
            }
          }
        }
      }
      await this.$nextTick();
      this.sendModalOpen = false;
    },
    async openBookkeepingWizardModal() {
      this.bookkeepingWizardOpen = true;
      await this.$nextTick();
      this.exportOrValidateModalOpen = false;
    },
    async exportOnlyValid() {
      this.exporting = true;
      await this.$nextTick();
      if (this.validIdsForExport.length > 0) {
        await this.exportEntriesByBillingIds(this.validIdsForExport);
      }
      this.exportOrValidateModalOpen = false;
      this.exporting = false;
    },
    async downloadFile() {
      window.open(this.downloadUrl, '_blank');
    },
    translateTableHeader(columnKey) {
      return this.$t(`bookkeeping.bookkeepingManagement.unsentDataTransferBillings.table.headers.${columnKey}`);
    },
    getChargeAccount(charge) {
      return charge?.account ?? '-';
    },
    getChargeAmount(charge) {
      return charge?.amount
        ? Number(charge.amount).toLocaleString(this.$i18n.locale, {
            ...currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        : '-';
    },
    handleRowClick(index) {
      this.billingEntriesScoutingOpen = true;
      this.scoutingIndex = this.pageSize * this.currentPageIndex + index;
    },
    async handleEntriesUpdate(entryIdsToExclude) {
      if (entryIdsToExclude) {
        await Promise.all(entryIdsToExclude?.map((id) => this.excludeBillingEntry(id)));

        const billingEntriesToExclude = this.billingEntries?.filter(({ id }) => includes(id, entryIdsToExclude));
        this.billingIdsToUpdate = without(
          billingEntriesToExclude.map(({ billingId }) => billingId),
          this.billingIdsToUpdate
        );

        this.$message.success(
          this.$t('bookkeeping.bookkeepingManagement.unsentDataTransferBillings.notifications.excludeSuccess')
        );
      }

      this.$emit('billing-entries-update');
      this.excludeConfirmTransactionId = null;
    },
    handleExcludeCancel() {
      this.excludeConfirmTransactionId = null;
    },
    handleAction(command, rowData) {
      switch (command) {
        case ACTIONS.EXCLUDE_ENTITY: {
          const { bookkeepingEntryId } = rowData;
          this.excludeConfirmTransactionId = bookkeepingEntryId;
          break;
        }
        default:
          break;
      }
    },
    actionsVisibleChange(index, isVisible) {
      this.activeRowActionsIndex = isVisible ? index : -1;
    },
    closeActions() {
      if (this.activeRowActionsIndex !== -1) {
        document.getElementById(`actions-row-${this.activeRowActionsIndex}`).click();
        this.activeRowActionsIndex = -1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.unexported-billings-table {
  .actions-btn {
    &.active {
      visibility: visible;
    }
  }

  tr {
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}
</style>
