import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useBillingsExportFilesLink(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError } = useQuery(BILLINGS_EXPORT_LINK_QUERY, variables, options);
  const billingsExportFilesLink = computed(() => result?.value?.billingsExportFilesLink?.exportFilesLink);

  const loading = useLoading(queryLoading, variables, options);

  onError((err) => {
    console.error('bookkeeper - useBillingsExportFilesLink', err);
    error();
  });

  return {
    billingsExportFilesLink,
    loading,
  };
}

const BILLINGS_EXPORT_LINK_QUERY = gql`
  query getBillingsExportFilesLink($id: ID!) {
    billingsExportFilesLink(id: $id) {
      exportFilesLink
    }
  }
`;
