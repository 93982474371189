import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useBillingsExports(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(BILLINGS_EXPORTS_QUERY, variables, options);
  const billingsExports = computed(() => result?.value?.billingsExports?.nodes ?? []);

  const loading = useLoading(queryLoading, variables, options);

  onError((err) => {
    console.error('bookkeeper - useBillingsExports', err);
    error();
  });

  return {
    billingsExports,
    refetch,
    loading,
  };
}

const BILLINGS_EXPORTS_QUERY = gql`
  query billingsExports($bookkeeperId: ID, $customerId: ID) {
    billingsExports(bookkeeperId: $bookkeeperId, customerId: $customerId) {
      nodes {
        id
        customerId
        bookkeeperId
        suppliersCount
        documentsCount
        totalNetAmount
        totalTaxAmount
        totalAmount
        createdAt
        createdBy {
          profile {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
