import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification, useLoading } from '@/modules/core';

export function useBillingEntries(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(BILLING_ENTRIES_QUERY, variables, options);
  const billingEntriesConnection = useResult(result, { nodes: [], totalCount: 0 });
  const billingEntries = computed(() => billingEntriesConnection.value.nodes);
  const totalCount = computed(() => billingEntriesConnection.value.totalCount);

  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useBillingEntries', err);
    error();
  });

  return {
    billingEntries,
    totalCount,
    loading,
    refetch,
  };
}

const BILLING_ENTRIES_QUERY = gql`
  query getBillingEntries($bookkeeperId: ID, $customerId: ID, $exported: Boolean, $billingsExportId: ID) {
    billingEntries(
      bookkeeperId: $bookkeeperId
      customerId: $customerId
      exported: $exported
      billingsExportId: $billingsExportId
    ) {
      nodes {
        id
        billingId
        customerId
        supplierId
        bookkeeperId
        exported
        bookkeepingEntry {
          code
          reference
          allocationNumber
          referenceDate
          valueDate
          details
          debits {
            account
            amount
          }
          credits {
            account
            amount
          }
        }
      }
      totalCount
    }
  }
`;
