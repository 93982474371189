import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useBillingsExport(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(BILLINGS_EXPORT_QUERY, variables, options);
  const billingsExport = useResult(result, []);

  const loading = useLoading(queryLoading, variables, options);

  onError((err) => {
    console.error('bookkeeper - useBillingsExport', err);
    error();
  });

  return {
    billingsExport,
    refetch,
    loading,
  };
}

const BILLINGS_EXPORT_QUERY = gql`
  query getBillingsExport($id: ID) {
    billingsExport(id: $id) {
      id
      customerId
      bookkeeperId
      suppliersCount
      documentsCount
      totalNetAmount
      totalTaxAmount
      totalAmount
      createdAt
      createdBy {
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;
