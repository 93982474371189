import { computed } from 'vue';

import { useLoading } from '@/modules/core';

import {
  useBookkeepingAccounts,
  useCreateBookkeepingAccount,
  useUpdateBookkeepingAccount,
  useDeleteBookkeepingAccount,
} from '.';
import { ACCOUNT_TYPES } from '../../constants';

export const useBookkeepingAccountsData = (variables) => {
  const options = computed(() => ({ enabled: !!variables.value.customerId && !!variables.value.bookkeeperId }));
  const { bookkeepingAccounts, refetch, loading: queryLoading } = useBookkeepingAccounts(variables, options);

  const loading = useLoading(queryLoading, variables, options);

  const { createBookkeepingAccount, onDone: onAccountCreated } = useCreateBookkeepingAccount();
  const { updateBookkeepingAccount, onDone: onAccountUpdated } = useUpdateBookkeepingAccount();
  const { deleteBookkeepingAccount, onDone: onAccountDeleted } = useDeleteBookkeepingAccount();

  onAccountCreated(() => refetch());
  onAccountUpdated(() => refetch());
  onAccountDeleted(() => refetch());

  const createOrUpdateBookkeepingAccount = (
    currentAccount,
    accountType,
    newAccountNumber,
    bookkeeperId,
    supplierId
  ) => {
    if (currentAccount) {
      return updateBookkeepingAccount({ id: currentAccount.id, data: { account: newAccountNumber } });
    } else {
      const createParams = {
        bookkeeperId,
        customerId: variables.value.customerId,
        type: accountType,
        account: newAccountNumber,
      };
      if (accountType !== ACCOUNT_TYPES.VAT) createParams.supplierId = supplierId;
      return createBookkeepingAccount({ createParams });
    }
  };

  const reomveBookkeepingAccount = (currentAccount) => deleteBookkeepingAccount({ id: currentAccount.id });

  return {
    bookkeepingAccounts,
    loading,
    createOrUpdateBookkeepingAccount,
    deleteBookkeepingAccount: reomveBookkeepingAccount,
  };
};
