import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useDeleteBookkeepingAccount() {
  const { error } = useNotification();
  const { mutate, loading, onDone, onError } = useMutation(DELETE_BOOKKEEPING_ACCOUNT_MUTATION);

  onError((err) => {
    console.error('bookkeeping - useDeleteBookkeepingAccount', err);
    error();
  });

  return {
    deleteBookkeepingAccount: mutate,
    loading,
    onDone,
    onError,
  };
}

const DELETE_BOOKKEEPING_ACCOUNT_MUTATION = gql`
  mutation bookkeepingAccountDelete($id: ID!) {
    bookkeepingAccountDelete(id: $id) {
      id
    }
  }
`;
