import { gql } from '@apollo/client/core';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useBookkeeperAggregations(variables) {
  const { error } = useNotification();

  const {
    result: billingEntryAggregationsResult,
    loading: billingEntryAggregationsLoading,
    onError: onBillingEntryAggregationError,
  } = useQuery(
    BILLING_ENTRY_AGGREGATIONS_QUERY,
    {
      groupBy: 'customerId',
      bookkeeperId: variables.value.bookkeeperId,
      toDate: DateTime.local().startOf('month').minus({ day: 1 }).endOf('day').toISODate(),
    },
    () => ({
      enabled: !!variables.value.bookkeeperId,
    })
  );

  const {
    result: billingsExportAggregationsResult,
    loading: billingsExportAggregationLoading,
    onError: onbillingsExportAggregationError,
  } = useQuery(
    BILLINGS_EXPORT_AGGREGATIONS_QUERY,
    {
      groupBy: 'customerId',
      bookkeeperId: variables.value.bookkeeperId,
    },
    () => ({
      enabled: !!variables.value.bookkeeperId,
    })
  );

  onBillingEntryAggregationError((err) => {
    console.error('useBookkeeperAggregations', err);
    error();
  });
  onbillingsExportAggregationError((err) => {
    console.error('useBookkeeperAggregations', err);
    error();
  });

  const billingEntryAggregations = useResult(billingEntryAggregationsResult, { nodes: [] });
  const billingsExportAggregations = useResult(billingsExportAggregationsResult, { nodes: [] });

  const bookkeeperAggregations = computed(() => {
    const aggregationsByCustomer = {};
    billingEntryAggregations.value.nodes.forEach((billingEntryAggregation) => {
      aggregationsByCustomer[billingEntryAggregation.customerId] = {
        count: billingEntryAggregation.count,
        customerName: billingEntryAggregation.customer.name,
      };
    });
    billingsExportAggregations.value.nodes.forEach((billingsExportAggregation) => {
      aggregationsByCustomer[billingsExportAggregation.customerId] = {
        ...aggregationsByCustomer[billingsExportAggregation.customerId],
        lastBatch: billingsExportAggregation.lastBatch,
        customerName: billingsExportAggregation.customer.name,
      };
    });
    return aggregationsByCustomer;
  });

  return {
    loading: computed(() => billingEntryAggregationsLoading.value || billingsExportAggregationLoading.value),
    bookkeeperAggregations,
  };
}

const BILLING_ENTRY_AGGREGATIONS_QUERY = gql`
  query getBillingEntryAggregations($bookkeeperId: ID, $toDate: ISODate, $groupBy: BillingEntryAggregationGroupBy!) {
    billingEntryAggregations(bookkeeperId: $bookkeeperId, toDate: $toDate, groupBy: $groupBy) {
      nodes {
        customerId
        customer {
          name
        }
        count
      }
    }
  }
`;
const BILLINGS_EXPORT_AGGREGATIONS_QUERY = gql`
  query getBillingsExportAggregations($bookkeeperId: ID, $groupBy: BillingsExportAggregationGroupBy!) {
    billingsExportAggregations(bookkeeperId: $bookkeeperId, groupBy: $groupBy) {
      nodes {
        customerId
        customer {
          name
        }
        lastBatch
      }
    }
  }
`;
