<template>
  <el-dialog visible :show-close="false" custom-class="rounded popup-layout" :width="width">
    <template #title>
      <div class="d-flex justify-content-between">
        <slot name="title" />
        <Button :disabled="loading" type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
      <slot name="subtitle" />
    </template>
    <slot name="body" />
    <template #footer>
      <slot name="buttons" />
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

export default {
  components: { Button, CloseIcon },
  props: {
    loading: { type: Boolean, default: false },
    width: { type: String, default: '432px' },
  },
};
</script>

<style scoped lang="scss">
:deep(.popup-layout) {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 1rem;
  }

  .title {
    font-size: 1.25rem;
  }
}
</style>
