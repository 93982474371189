import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

export function useBusiness(variables) {
  const { result } = useQuery(BUSINESS_BY_ID, { supplierId: variables.value.businessId }, () => ({
    enabled: !!variables.value.businessId,
  }));
  const business = useResult(result, { id: variables.value.businessId });

  return {
    business,
  };
}

// At the moment we are getting the business via the supplier query
const BUSINESS_BY_ID = gql`
  query supplier($supplierId: ID!) {
    supplier(id: $supplierId) {
      id
      name
    }
  }
`;
