import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useBillingsExportCreate() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(BILLING_EXPORTS_MUTATION);

  onError((err) => {
    console.error('bookkeeper - useBillingsExportCreate', err);
    const { graphQLErrors } = err;
    if (graphQLErrors.length) {
      for (const gqlError of graphQLErrors) {
        if (gqlError.extensions?.response?.status !== 409) {
          error();
        } else {
          throw err;
        }
      }
    }
  });

  return {
    billingsExportCreate: mutate,
    loading,
    onDone,
    onError,
  };
}

const BILLING_EXPORTS_MUTATION = gql`
  mutation createBillingsExport($customerId: ID, $billingIds: [ID], $bookkeeperId: ID) {
    billingsExportCreate(customerId: $customerId, billingIds: $billingIds, bookkeeperId: $bookkeeperId) {
      url
      invalidBillingIds
    }
  }
`;
