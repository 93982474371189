import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useUpdateBookkeepingAccount() {
  const { error } = useNotification();
  const { mutate, loading, onDone, onError } = useMutation(UPDATE_BOOKKEEPING_ACCOUNT_MUTATION);

  onError((err) => {
    console.error('bookkeeping - useUpdateBookkeepingAccount', err);
    error();
  });

  return {
    updateBookkeepingAccount: mutate,
    loading,
    onDone,
    onError,
  };
}

const UPDATE_BOOKKEEPING_ACCOUNT_MUTATION = gql`
  mutation bookkeepingAccountPatch($id: ID!, $data: BookkeepingAccountPatchInput!) {
    bookkeepingAccountPatch(id: $id, data: $data) {
      id
      type
      customerId
      supplierId
      bookkeeperId
      account
    }
  }
`;
