import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const useCustomerSuppliers = () => {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(SUPPLIERS_QUERY, {}, () => ({ fetchPolicy: 'cache-first' }));
  const suppliers = computed(() => result.value?.suppliers ?? []);

  onError((err) => {
    console.error('bookkeeper useCustomerSuppliers', err);
    error();
  });

  return { suppliers, loading };
};

export const useCustomerSuppliersNew = () => {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(SUPPLIERS_NEW_QUERY, {}, () => ({ fetchPolicy: 'cache-first' }));
  const suppliers = computed(() => result.value?.suppliers ?? []);

  onError((err) => {
    console.error('bookkeeper useCustomerSuppliers', err);
    error();
  });

  return { suppliers, loading };
};

const SUPPLIERS_NEW_QUERY = gql`
  query suppliers {
    suppliersNew {
      nodes {
        id
        name
        number
      }
    }
  }
`;

const SUPPLIERS_QUERY = gql`
  query suppliers {
    suppliers {
      id
      name
      number
    }
  }
`;
